<div class="geschaeftskonto-container">
  <div class="geschaeftskonto-box box-shadow">
    <div style="display: flex">
      <h2 class="title-opta" appTranslationModal [translate]="'PRODUKTAUSWAHL.GESCHAEFTSKONTO'"></h2>
      <app-info-card class="info-text">
        <ng-container *ngIf="infoText; instanceof: TemplateRef; else: stringContent">
          <ng-container *ngTemplateOutlet="infoText"></ng-container>
        </ng-container>
        <ng-template #stringContent>
          <p [innerHTML]="infoText"></p>
        </ng-template>
      </app-info-card>
    </div>

    <p class="sub-text" appTranslationModal [translate]="'PRODUKTAUSWAHL.GESCHAEFTSKONTOALLINK'"></p>

    <div class="euro-container">
      <h1 class="euro" appTranslationModal>
        {{ kontofuehrungsgebuehr }}
      </h1>
    </div>
  </div>
</div>
