import { NgIf } from '@angular/common';
import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogTitle } from '@angular/material/dialog';
import { MatIcon } from '@angular/material/icon';
import { ButtonComponent } from '../button/button.component';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss'],
  imports: [MatIcon, ButtonComponent],
})
export class InfoCardComponent {
  @ViewChild('infoContent') infoContent: TemplateRef<any>;

  @Input() header: string;

  constructor(private dialog: MatDialog) {}

  openDialog(): void {
    this.dialog.open(this.infoContent);
  }

  closeModal() {
    this.dialog.closeAll();
  }
}
