<div class="dialogBox">
  <div *ngIf="!pepConfirm">
    <h3 appTranslationModal [translate]="'RECHTLICHES.POPUP_TITLE'"></h3>
    <div style="display: flex; justify-content: space-evenly">
      <app-button color="accent" [mat-dialog-close]="false">nein</app-button>
      <app-button color="accent" (click)="changeBox()">ja</app-button>
    </div>
  </div>
  <div *ngIf="pepConfirm">
    <h3 appTranslationModal [translate]="'RECHTLICHES.POPUP_END'"></h3>
    <div style="display: flex; justify-content: space-evenly">
      <app-button color="accent" [mat-dialog-close]="true">Schließen</app-button>
    </div>
  </div>
</div>
