import { DataService } from 'src/app/services/data.service';

import { Component, Inject, OnInit } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
} from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { FormsModule } from '@angular/forms';
import { ButtonComponent } from 'src/app/component/button/button.component';

/**
 * Translate modal mit ein text feld um die texte übersetzen.
 */
@Component({
  selector: 'app-translate-modal',
  templateUrl: './translate-modal.component.html',
  imports: [MatDialogTitle, CdkScrollable, MatDialogContent, FormsModule, MatDialogActions, ButtonComponent],
})
export class TranslateModalComponent {
  text: string;
  currentLang: string;

  constructor(
    private translate: TranslateService,
    private dataService: DataService,
    private dialogRef: MatDialogRef<TranslateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.currentLang = this.translate.currentLang;
    this.translate.get(this.data.translationKey).subscribe((t) => (this.text = t));
  }

  d() {
    this.dialogRef.close();
  }

  save() {
    this.translate.getTranslation(this.currentLang).subscribe((currentTranslation) => {
      if (currentTranslation instanceof Array) currentTranslation = {};
      this._createJsonTree(currentTranslation, this.data.translationKey, this.text.trim());

      this.translate.setTranslation(this.currentLang, currentTranslation, true);

      this.dataService.setTranslation(this.currentLang, currentTranslation).subscribe(() => {
        this.dialogRef.close();
      });
    });
  }

  _createJsonTree(obj: any, fullKey: string, value: string) {
    const splitedKey = fullKey.split('.');
    const [key, ...remainingKeys] = splitedKey;

    if (!obj[key]) {
      obj[key] = {};
    }

    if (remainingKeys.length === 0) {
      obj[key] = value;
      return;
    }

    return this._createJsonTree(obj[key], remainingKeys.join('.'), value);
  }
}
