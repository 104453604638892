import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { TranslationDirective } from '../translata-modal/translation.directive';
import { InfoCardComponent } from '../info-card/info-card.component';
import { CommonModule, NgIf } from '@angular/common';

@Component({
  selector: 'app-geschaeftskonto',
  imports: [TranslationDirective, TranslateModule, InfoCardComponent, NgIf, CommonModule],
  templateUrl: './geschaeftskonto.component.html',
  styleUrl: './geschaeftskonto.component.scss',
})
export class GeschaeftskontoComponent {
  @Input() infoText: any;
  @Input() kontofuehrungsgebuehr: any;
}
