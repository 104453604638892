import { CommonModule, NgIf } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CheckBoxComponent } from '../checkbox/checkbox.component';
import { InfoCardComponent } from '../info-card/info-card.component';

@Component({
  selector: 'app-zubuchbar-card',
  imports: [CheckBoxComponent, CheckBoxComponent, TranslateModule, InfoCardComponent, NgIf, CommonModule],
  templateUrl: './zubuchbar-card.component.html',
  styleUrl: './zubuchbar-card.component.scss',
})
export class ZubuchbarCardComponent {
  @Input() title: string = '';
  @Input() subtitle: string = '';
  @Input() text: string = '';
  @Input() infoText: any;
  @Input() checkboxFormControl;
  @Input() checkboxDisabled;
  @Input() showAdditionalCheckbox: boolean = false;
  @Input() disabled: boolean = false;
}
