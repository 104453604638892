<h4 mat-dialog-title>[{{ currentLang }}] Text update</h4>
<div mat-dialog-content>
  <div>
    <span> {{ data.translationKey }} </span>
  </div>
  <textarea name="text" rows="8" cols="80" [(ngModel)]="text" placeholder="translation..."> </textarea>
</div>
<div mat-dialog-actions>
  <app-button (click)="d()">Cancel</app-button>
  <app-button (click)="save()" cdkFocusInitial>Save</app-button>
</div>
