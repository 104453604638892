import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  imports: [CommonModule, TranslateModule],
})
export class ButtonComponent {
  @Input() text?: string | undefined;
  @Input() disabled?: boolean | null;
  @Input() color?: 'primary' | 'accent';
  constructor() {}
}
