import { HttpClient } from '@angular/common/http';
import { computed, effect, inject, Injectable, signal, WritableSignal } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Question } from '../models/question';
import { User } from '../models/user';
import { QuestionTemplate } from '../pages/questionnaire/questions/question-template';
import { AuthService } from './auth.service';

export class QuestionData {
  route: string;
  component?: QuestionTemplate;
  stepPage: boolean;
  showHeader?: boolean;
  showIconInButton?: boolean;
  nextButtonTitle: string;
  description?: { title: string; text: string };
  imagePath: string;
  step: number;
  label: string;
}

/**
 * Service mit die config und funktionalität für die fragewogen.
 * Hier sind die route von jede frage, mit ten title und text die in die web auf die rechte seite angezeigt wird.
 * Diese title und hilfe text kann mann finden in der questionnnaire component.
 */
@Injectable({
  providedIn: 'root',
})
export class QuestionService {
  questionIndex: WritableSignal<number> = signal(0);
  questionList: WritableSignal<Array<QuestionData>> = signal(
    new Array(
      {
        route: 'overview',
        stepPage: false,
        showHeader: false,
        showIconInButton: false,
        nextButtonTitle: '',
        imagePath: 'assets/img/overview-mainImage',
        label: 'Willkommen',
        step: -1,
      },
      {
        route: 'bank-wahl',
        stepPage: true,
        showHeader: true,
        showIconInButton: true,
        nextButtonTitle: 'Nächster Schritt – Bestätigung Stammdaten',
        imagePath: 'assets/img/overview-mainImage',
        description: {
          title: '',
          text: this.translate.instant('PRODUKTAUSWAHL.INFO_TEXT'),
        },
        step: 0,
        label: 'Produktauswahl',
      },
      {
        route: 'zusammenfassung',
        stepPage: true,
        showHeader: true,
        showIconInButton: true,
        nextButtonTitle: 'Nächster Schritt - Rechtliche Angaben',
        imagePath: 'assets/img/stammdaten',
        step: 1,
        label: 'Stammdaten',
      },
      {
        route: 'dokumente',
        stepPage: true,
        showHeader: true,
        showIconInButton: true,
        nextButtonTitle: 'Nächster Schritt - Rechtliche Angaben',
        imagePath: 'assets/img/stammdaten',
        step: 1,
        label: 'Stammdaten & Rechtliches',
      },
      {
        route: 'blanko-dokumente',
        stepPage: true,
        showHeader: true,
        showIconInButton: true,
        nextButtonTitle: 'Nächster Schritt - Geschäftskonto eröffnen',
        imagePath: 'assets/img/rechtliches',
        step: 1,
        label: 'Dokumente',
      },
      {
        route: 'einsicht',
        stepPage: true,
        showHeader: true,
        showIconInButton: false,
        nextButtonTitle: 'Jetzt Geschäftskonto eröffnen',
        imagePath: 'assets/img/abschluss',
        step: 2,
        label: 'Geschäftskonto eröffnen',
      },
      {
        route: 'identifizierung',
        stepPage: false,
        showHeader: true,
        showIconInButton: false,
        nextButtonTitle: 'Gleich weiter zum Dashboard',
        imagePath: 'assets/img/abschluss',
        step: 3,
        label: 'Geschäftskonto eröffnen',
      }
    )
  );

  stepBase = signal([
    { label: 'Produktauswahl', current: false, done: false, step: 0 },
    { label: 'Stammdaten', current: false, done: false, step: 1 },
    { label: 'Geschäftskonto eröffnen', current: false, done: false, step: 2 },
  ]);

  steps = computed(() => this.updateStepsForStepper(this.questionList()[this.questionIndex()].step));

  router = inject(Router);
  route = inject(ActivatedRoute);
  http = inject(HttpClient);
  auth = inject(AuthService);

  constructor(private readonly translate: TranslateService) {
    effect(() => {
      const updatedList = this.initJurPerson();
      if (updatedList.length !== this.questionList().length) {
        this.questionList.update(() => updatedList); // Nur hier das Signal explizit aktualisieren
      }
      //this.updateStepsForStepper();
    });
  }

  initJurPerson() {
    if (!this.auth.isJurPerson()) {
      const newList = this.questionList().filter((item) => item.route !== 'dokumente');
      return newList; // Kein direktes Update
    }
    return this.questionList(); // Originale Liste, wenn keine Änderung notwendig ist
  }
  /**
   * load question
   * @param route route name
   * @param component component kind zu laden
   */
  setComponent(route: string, component: QuestionTemplate) {
    //this.initJurPerson();
    const questions = this.questionList();
    const foundIndex = questions.findIndex((item) => item.route === route);

    if (foundIndex !== -1) {
      this.questionIndex.update(() => foundIndex);

      const updatedQuestions = [...questions];
      updatedQuestions[foundIndex] = {
        ...updatedQuestions[foundIndex],
        component: component,
      };

      this.questionList.update(() => updatedQuestions);
    }
  }

  getQuestionObjByRoute(route: string) {
    //this.initJurPerson();
    return this.questionList().find((q) => q.route === route);
  }

  getQuestionIndexByRoute(route: string) {
    if (route === '') throw Error('empty route');
    //this.initJurPerson();
    return this.questionList().findIndex((q) => q.route === route.trim());
  }

  getQuestionObj(index: number) {
    this.initJurPerson();
    if (index < this.questionList().length) {
      return this.questionList()[index];
    } else throw Error();
  }

  getComponent(index: number) {
    //this.initJurPerson();
    return this.questionList()[index].component;
  }

  getRouteName(index: number) {
    //this.initJurPerson();
    if (index < this.questionList().length) return this.questionList()[index].route;
    else return null;
  }

  saveQuestions(userId, userquestions) {
    return this.http.post<User>(
      `${environment.apiURL}/user/questionaire`,
      { userid: userId, userquestions: userquestions },
      { withCredentials: true }
    );
  }

  getLastQuestion(userQuestionList: Question[]): QuestionData {
    //this.initJurPerson();
    let last = this.questionList()[0];
    this.questionList().forEach((element, index) => {
      for (const question of userQuestionList) {
        if (question.name === this.questionList()[index].route) {
          last = this.questionList()[index + 1];
        }
      }
    });
    return last;
  }

  nextStep() {
    const currentPath = this.getCurrentPath();
    this.questionIndex.update(() => this.getQuestionIndexByRoute(currentPath));
    const component = this.getComponent(this.questionIndex());
    const nextRoute = this.getRouteName(this.questionIndex() + 1);
    // not allow spring questions on double click
    if (nextRoute && component && (!component.answerFormGroup || component.answerFormGroup.valid)) {
      this.router.navigate([nextRoute], { relativeTo: this.route });
      //   await this._init(nextRoute);
    }
  }

  getCurrentPath() {
    return window.location.href.replace('://', '').split('/')[1];
  }

  getNextButtonTitle() {
    const currentPath = this.getCurrentPath();
    //this.questionIndex.update(() => this.getQuestionIndexByRoute(currentPath));
    return this.questionList()[this.getQuestionIndexByRoute(currentPath)].nextButtonTitle;
  }

  private updateStepsForStepper(currentStep) {
    const newSteps = structuredClone(this.stepBase());
    for (const step of newSteps) {
      if (currentStep === -1) {
        step.current = false;
        step.done = false;
      } else if (step.step < currentStep) {
        step.done = true;
        step.current = false;
      } else if (step.step === currentStep) {
        step.current = true;
      }
    }
    return newSteps;
  }
}
