<div class="zusammenfassung-container">
  <h1 class="text-primary" appTranslationModal [translate]="'STAMMDATEN.TITLE_TEXT'"></h1>

  <app-content-card>
    <form [formGroup]="answerFormGroup">
      <div class="row">
        <div class="col-12">
          <!-- Firmeninformation Section -->

          <div class="row section-container">
            <h2 class="text-primary" appTranslationModal [translate]="'STAMMDATEN.FIRMENINFORMATION'"></h2>
            <div class="col-12">
              <app-inputtext
                [label]="juriPerson ? 'STAMMDATEN.EINGETRAGENERFIRMENNAMENAME' : 'STAMMDATEN.EINGETRAGENERNAME'"
                [control]="answerFormGroup.get('eingetragenerName')"
                [readonly]="true"
              ></app-inputtext>
            </div>
            <div class="col-12" *ngIf="juriPerson">
              <app-inputtext
                [label]="'STAMMDATEN.RECHTSFORMAUSPRÄGUNG'"
                [value]="rechtsformAuspraegungControl.value"
                [readonly]="true"
              ></app-inputtext>
            </div>
          </div>

          <!-- Inhaber Section (if !juriPerson) -->
          <div class="row section-container" *ngIf="!juriPerson">
            <h2 class="text-primary" appTranslationModal [translate]="'STAMMDATEN.INHABER'"></h2>
            <ng-container
              *ngFor="
                let field of ['anrede', 'titel', 'vorname', 'nachname', 'geburtsdatum', 'nationalität'];
                let i = index
              "
            >
              <div [class]="i < 2 || i > 3 ? 'col-6' : 'col-12 col-lg-6'">
                <app-inputtext
                  [label]="'STAMMDATEN.' + field.toUpperCase()"
                  [control]="answerFormGroup.get(field)"
                  [readonly]="true"
                ></app-inputtext>
              </div>
            </ng-container>
          </div>

          <!-- Firmenanschrift Section -->

          <div class="row section-container">
            <h2 class="text-primary" appTranslationModal [translate]="'STAMMDATEN.FIRMENANSCHRIFT'"></h2>
            <ng-container *ngFor="let field of ['strasseMitHausnummer', 'postleitzahl', 'ort', 'land']; let i = index">
              <div
                [class]="field === 'postleitzahl' ? 'col-12 col-lg-4' : field === 'ort' ? 'col-12 col-lg-8' : 'col-12'"
              >
                <app-inputtext
                  [label]="'STAMMDATEN.' + field.toUpperCase()"
                  [control]="answerFormGroup.get(field)"
                  [readonly]="true"
                ></app-inputtext>
              </div>
            </ng-container>
          </div>

          <!-- Steuernummer and Handelsregister Section (if juriPerson) -->
          <div class="row section-container" *ngIf="juriPerson">
            <h2 class="text-primary" appTranslationModal [translate]="'STAMMDATEN.STEUERNUMMERHANDELSREGISTER'"></h2>
            <div class="col-12" style="position: relative">
              <img
                (click)="steuerIdModalToggle()"
                class="edit-steuernummer-button"
                [src]="'assets/icons/DokumentmitStift_RGB_dunkel.svg'"
                alt="edit_steuerid"
                width="20px"
                height="24px"
              />

              <!-- <app-button
                class="edit-button"
                [ngClass]="{
                  '': !steuerIdEditable,
                  'color-change': steuerIdEditable,
                }"
                color="accent"
                (click)="steuerIdModalToggle()"
              >
                <img src="assets/icons/DokumentmitStift_RGB_weiß.png" alt="edit_steuerid" width="24px" height="24px" />
              </app-button> -->

              <app-inputtext
                [label]="'STAMMDATEN.STEUERNUMMER_FÜR_ERTRAGSSTEUERZWECKE'"
                [control]="answerFormGroup.get('Steuernummer')"
                [readonly]="true"
              >
              </app-inputtext>

              <div class="info-mark">
                <app-info-card>
                  <p [innerHTML]="'STAMMDATEN.DESCRIPTION' | translate"></p>
                </app-info-card>
              </div>
            </div>
            <!-- <div class="col-2 btn-right">
              <app-button
                [ngClass]="{
                  '': !steuerIdEditable,
                  'color-change': steuerIdEditable,
                }"
                color="accent"
                (click)="steuerIdModalToggle()"
              >
                <img src="assets/icons/DokumentmitStift_RGB_weiß.png" alt="edit_steuerid" width="24px" height="24px" />
              </app-button>
            </div> -->
            <ng-container *ngFor="let field of ['registergericht', 'registernummer']">
              <div class="col-12">
                <app-inputtext
                  [label]="'STAMMDATEN.' + field.toUpperCase()"
                  [control]="answerFormGroup.get(field)"
                  [readonly]="true"
                ></app-inputtext>
              </div>
            </ng-container>
          </div>

          <!-- Ausweisdaten Section -->
          <div class="row section-container">
            <h2 class="text-primary" appTranslationModal [translate]="'STAMMDATEN.AUSWEISDATEN'"></h2>
            <ng-container
              *ngFor="
                let field of ['ausweisnummer', 'ausgestelltAm', 'gültigBis', 'ausweisart', 'auszustellendeBehörde']
              "
            >
              <div [class]="field === 'auszustellendeBehörde' ? 'col-12 col-lg-8' : 'col-12 col-lg-4'">
                <app-inputtext
                  [label]="'STAMMDATEN.' + field.toUpperCase()"
                  [control]="answerFormGroup.get(field)"
                  [readonly]="true"
                ></app-inputtext>
              </div>
            </ng-container>
          </div>

          <!-- Kontaktdaten Section -->

          <div class="row section-container">
            <h2 class="text-primary" appTranslationModal [translate]="'STAMMDATEN.KONTAKTDATEN'"></h2>
            <div class="col-12" style="position: relative">
              <img
                class="edit-telefonnummer-button"
                [src]="'assets/icons/DokumentmitStift_RGB_dunkel.svg'"
                alt="edit_telefonnummer"
                width="20px"
                height="24px"
                (click)="telefonEditableToggle()"
              />

              <app-inputtext
                [label]="'STAMMDATEN.EMAIL'"
                [control]="answerFormGroup.get('email')"
                [readonly]="true"
              ></app-inputtext>

              <app-inputtext
                [label]="'Telefonnummer'"
                [control]="answerFormGroup.get('telefon')"
                [readonly]="true"
              ></app-inputtext>

              <!-- <div class="col-12 col-lg-6 btn-right">
              <app-button
                [ngClass]="{
                  '': !telefonEditable,
                  'color-change': telefonEditable,
                }"
                color="accent"
                (click)="telefonEditableToggle()"
                ><img
                  src="assets/icons/DokumentmitStift_RGB_weiß.png"
                  alt="edit_telefonnummer"
                  width="24px"
                  height="24px"
                />
              </app-button>
            </div> -->
            </div>
          </div>
        </div>
      </div>
    </form>
  </app-content-card>

  <div class="info-container">
    <div class="info-text-icon">
      <mat-icon *ngIf="auth.user.lang === 'odfin'" class="error-icon odfincolor" style="overflow: inherit"
        >error</mat-icon
      >
      <p appTranslationModal [translate]="'STAMMDATEN.ABWEICHENDEN_STAMMDATEN_INFO'"></p>
    </div>
    <div class="col-2">
      <app-button color="accent" (click)="abweichungToggle()">
        {{ 'STAMMDATEN.ABWEICHUNGEN_MELDEN' | translate }}
      </app-button>
    </div>
  </div>

  <app-content-card>
    <form [formGroup]="answerFormGroup">
      <div class="row" class="stammdaten-bestaetigung">
        <div class="col-12">
          <h2 appTranslationModal [translate]="'STAMMDATEN.BESTAETIGUNG_TITLE'"></h2>
          <hr />
          <p appTranslationModal [translate]="'STAMMDATEN.BESTAETIGUNG_TEXT'"></p>
          <div *ngIf="!juriPerson" class="confirm-checkbox">
            <app-checkbox
              [checkBoxControl]="answerFormGroup.controls['bestätigt']"
              data-cy="natPerson_stammdaten-bestaetigen"
            >
              <p class="text-bold" appTranslationModal [translate]="'STAMMDATEN.BESTAETIGUNG_CHECKBOX_TEXT'"></p
            ></app-checkbox>
          </div>

          <div *ngIf="juriPerson" class="confirm-checkbox">
            <app-checkbox
              [checkBoxControl]="answerFormGroup.controls['bestätigt_second']"
              data-cy="jurPerson_geschaeftsfuehrer-bestaetigen"
              ><p class="text-bold" appTranslationModal [translate]="'STAMMDATEN.BESTAETIGUNG_CHECKBOX_TEXT3'"></p
            ></app-checkbox>

            <app-checkbox
              [checkBoxControl]="answerFormGroup.controls['bestätigt']"
              data-cy="jurPerson_stammdaten-bestaetigen"
            >
              <p class="text-bold" appTranslationModal [translate]="'STAMMDATEN.BESTAETIGUNG_CHECKBOX_TEXT'"></p
            ></app-checkbox>
          </div>
        </div>
      </div>
    </form>
  </app-content-card>

  <app-separator></app-separator>

  <!--   <img src="assets/img/cover1.webp" alt="cover1" class="cover-img" width="500" /> -->

  <app-button
    (click)="btnService.handleSaveAndDisableButton()"
    color="primary"
    [disabled]="btnService.isButtonDisabled() || btnService.isSaveAndContinueDisabled"
  >
    <span class="button-size-big" appTranslationModal [translate]="questionService.getNextButtonTitle()"></span>
  </app-button>

  <app-separator></app-separator>
</div>

<app-modal-dialog
  [isVisible]="isSteuernummerDialogVisible"
  [showCloseButton]="true"
  [showAcceptButton]="true"
  acceptButtonText="Änderung speichern"
  closeButtonText="Abbrechen"
  (accept)="steuerIdModalToggle(answerFormGroup.get('Steuernummer')?.value)"
  (close)="steuerIdModalToggle()"
>
  <app-inputtext
    [label]="'STAMMDATEN.STEUERNUMMER_FÜR_ERTRAGSSTEUERZWECKE'"
    [control]="answerFormGroup.get('Steuernummer')"
    [readonly]="false"
  ></app-inputtext>
</app-modal-dialog>

<app-modal-dialog
  [isVisible]="isTelefonnummerDialogVisible"
  [showCloseButton]="true"
  [showAcceptButton]="true"
  acceptButtonText="Änderung speichern"
  closeButtonText="Abbrechen"
  (accept)="telefonEditableToggle(answerFormGroup.get('telefon')?.value)"
  (close)="telefonEditableToggle()"
>
  <app-inputtext
    [label]="'Telefonnummer'"
    [control]="answerFormGroup.get('telefon')"
    [readonly]="false"
  ></app-inputtext>
</app-modal-dialog>

<app-modal-dialog
  [isVisible]="isAbweichungmeldenDialogVisible"
  [showCloseButton]="true"
  [showAcceptButton]="true"
  acceptButtonText="Senden"
  closeButtonText="Abbrechen"
  (accept)="abweichungenSenden()"
  (close)="abweichungToggle()"
>
  <form [formGroup]="answerFormGroup">
    <div class="d-flex flex-column">
      <h2 appTranslationModal [translate]="'STAMMDATEN.ABWEICHUNGEN_TITLE'"></h2>

      <app-inputtext
        [control]="answerFormGroup.get('eingetragenerName')"
        [label]="'STAMMDATEN.FIRMENNAME' | translate"
        [readonly]="true"
      ></app-inputtext>

      <app-inputtext
        [control]="answerFormGroup.get('titel')"
        [readonly]="true"
        [label]="'STAMMDATEN.TITEL' | translate"
      ></app-inputtext>

      <app-inputtext
        [control]="answerFormGroup.get('vorname')"
        [label]="'STAMMDATEN.VORNAME' | translate"
        [readonly]="true"
      ></app-inputtext>

      <app-inputtext
        [control]="answerFormGroup.get('nachname')"
        [label]="'STAMMDATEN.NACHNAME' | translate"
        [readonly]="true"
      ></app-inputtext>

      <app-inputtext
        [control]="answerFormGroup.get('abweichungNachricht')"
        [label]="'STAMMDATEN.NACHRICHT' | translate"
      ></app-inputtext>
    </div>
  </form>
</app-modal-dialog>
