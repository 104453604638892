import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent } from '../button/button.component';
import { SeparatorComponent } from '../separator/separator.component';

@Component({
  selector: 'app-modal-dialog',
  imports: [CommonModule, ButtonComponent, SeparatorComponent],
  standalone: true,
  templateUrl: './modal-dialog.component.html',
  styleUrl: './modal-dialog.component.scss',
})
export class ModalDialogComponent {
  @Input() isVisible = false;
  @Input() showCloseButton = false;
  @Input() showAcceptButton = false;
  @Input() closeButtonText = '';
  @Input() acceptButtonText = '';

  @Output() close = new EventEmitter<void>();
  @Output() accept = new EventEmitter<void>();

  closeModal() {
    this.close.emit();
  }

  acceptModal() {
    this.accept.emit();
  }
}
