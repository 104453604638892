import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MatDialogClose } from '@angular/material/dialog';
import { AuthService } from 'src/app/services/auth.service';
import { NgClass, NgIf } from '@angular/common';
import { TranslationDirective } from '../../translata-modal/translation.directive';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from 'src/app/component/button/button.component';

@Component({
    selector: 'app-pep-dialog-box',
    templateUrl: './pep-dialog-box.component.html',
    styleUrls: ['./pep-dialog-box.component.scss'],
    imports: [
        NgIf,
        TranslationDirective,
        TranslateModule,
        ButtonComponent,
        MatDialogClose,
    ]
})
export class PepDialogBoxComponent {
  imagelang: string;
  pepConfirm: boolean = false;
  constructor(
    private dialogRef: MatDialogRef<PepDialogBoxComponent>,
    private auth: AuthService
  ) {
    dialogRef.disableClose = true;
    this.imagelang = this.auth.user.lang;
  }
  changeBox() {
    this.pepConfirm = true;
  }
}
