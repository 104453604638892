import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
})
export class CheckBoxComponent {
  @Input() checkBoxControl: FormControl<boolean> | AbstractControl<any, any>;
  @Input() isDisabled: boolean;
  constructor() {}

  triggervalidation(event: Event) {
    const input = event.target as HTMLInputElement;
    this.checkBoxControl.setValue(input.checked, { emitEvent: false });
    this.checkBoxControl.markAsDirty(); // Als "geändert" markieren
    this.checkBoxControl.updateValueAndValidity(); // Validierung triggern
  }
}
