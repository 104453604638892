<div class="md-stepper blue">
  <div *ngFor="let step of quests.steps(); index as i" class="md-step" [ngClass]="step.current ? 'active' : ''">
    <div class="md-step-circle" [ngClass]="{ 'current-circle': step.done }">
      <ng-container *ngIf="!step.done; else doneTemplate">
        <span>{{ i + 1 }}</span>
      </ng-container>
      <ng-template #doneTemplate>
        <img src="assets/icons/hakendunkel.png" alt="Haeckchen" width="20" height="20" />
      </ng-template>
    </div>
    <div class="md-step-title">{{ step.current ? step.label : '' }}</div>

    <div class="md-step-bar-left"></div>
    <div class="md-step-bar-right"></div>
  </div>
</div>
