import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { KeycloakService } from 'keycloak-angular';
import { Status, User } from '../models/user';

/**
 * Login/register service
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user: User;
  redirectUrl: string;
  index: number;
  keycloaktoken: any;
  berufsGruppe: Observable<any[]>;
  constructor(
    private http: HttpClient,
    private translate: TranslateService,
    private keycloakService: KeycloakService
  ) {}

  checkLogin(): Observable<boolean> {
    if (!this.user)
      return this.http
        .get<User>(`${environment.apiURL}/user`, { withCredentials: true })
        .pipe(
          map((resUser) => {
            this.user = new User(resUser);
            this.index = this.findFlaggedNaturalPerson(this.user.natPersonen);
            this.translate.use('odfin');
            return true;
          })
        )
        .pipe(shareReplay());
    else return of(true);
  }

  setKeycloakToken(token) {
    this.keycloaktoken = token;
  }

  getKeycloakToken() {
    return this.keycloaktoken;
  }

  setBerufsGruppe(berufsGruppe) {
    this.berufsGruppe = berufsGruppe;
  }

  getBerufsGruppe() {
    return this.berufsGruppe;
  }

  isJurPerson() {
    return this.user.isJurPerson ? true : false;
  }

  public isRechtsformGBR(): boolean {
    return this.user.JurPerson?.JurDaten?.GbrArt === 'GbR ohne Haftungsbeschraenkung';
  }

  findFlaggedNaturalPerson(natPersonen) {
    for (let i = 0; i < natPersonen.length; i++) {
      if (natPersonen[i].Person.VollmachtKontoeroeffnung) {
        return i;
      }
    }
    return 0;
  }

  refreshUser() {
    return this.http
      .get<User>(`${environment.apiURL}/user/refreshUser/${this.user._id}`)
      .pipe(
        map((res) => {
          this.user = new User(res);
          this.index = this.findFlaggedNaturalPerson(this.user.natPersonen);
          return res;
        })
      )
      .pipe(shareReplay());
  }

  refresh() {
    return this.http
      .get<User>(`${environment.apiURL}/user/refreshUser/${this.user._id}`)
      .pipe(
        map((res) => {
          return res;
        })
      )
      .pipe(shareReplay());
  }

  updateUser(userId: string, finapiCounter: Number) {
    return this.http.post(
      `${environment.apiURL}/user/updateUser`,
      { userid: userId, finapicounter: finapiCounter },
      { withCredentials: true }
    );
  }

  updateQuestions(userId: string) {
    return this.http.post(`${environment.apiURL}/user/updateQuestions`, { userid: userId }, { withCredentials: true });
  }

  updateAuswahlProdukt(userId: string) {
    return this.http.post(
      `${environment.apiURL}/user/updateauswahlprodukt`,
      { userid: userId },
      { withCredentials: true }
    );
  }

  login(uuid: string, email: string, institutions, institutionID) {
    if (this.user) return of(this.user);
    else
      return this.http
        .post<User>(
          `${environment.apiURL}/user/login`,
          {
            uuid: uuid,
            email: email.toLowerCase(),
            institutions: institutions,
            institutionID: institutionID,
          },
          { withCredentials: true }
        )
        .pipe(
          map((resUser) => {
            this.user = new User(resUser);
            this.index = this.findFlaggedNaturalPerson(this.user.natPersonen);
            this.translate.use('odfin');
            return this.user;
          })
        );
  }

  changeStatus(userId: String, status: Status) {
    return this.http.post<User>(
      `${environment.apiURL}/user/changeStatus`,
      { userid: userId, status: status },
      { withCredentials: true }
    );
  }

  getConfig(): Observable<any> {
    return this.http.get(`${environment.apiURL}/user/getConfig`, {
      withCredentials: true,
    });
  }

  hasKontowechselserviceSelected() {
    return this.http.get<boolean>(`${environment.apiURL}/user/has-kontowechservice-selected/${this.user._id}`, {
      withCredentials: true,
    });
  }

  logout() {
    this.keycloakService.logout();
    this.index = NaN;
    this.user = null as unknown as User;
  }
}
