import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-copy-element',
  templateUrl: './copy.component.html',
  styleUrls: ['./copy.component.scss'],
  imports: [TranslateModule],
})
export class CopyElementComponent {
  constructor() {}
}
