<ng-template #geschaeftskonto>
  <!-- geschafteskonto -->
  <p class="header-info-text" appTranslationModal [translate]="'PRODUKTAUSWAHL.GESCHAEFTSKONTO'"></p>
  <div class="p-3">
    <p
      class="description-text"
      appTranslationModal
      [translate]="'PRODUKTAUSWAHL.GESCHAEFTSKONTO_BESCHREIBUNG_VORTEILE'"
    ></p>
    <br />
    <div class="line-wrapper-end">
      ✅
      <p
        class="description-text"
        appTranslationModal
        [translate]="'PRODUKTAUSWAHL.GESCHAEFTSKONTO_BESCHREIBUNG_VORTEIL1'"
      ></p>
    </div>
    <div class="line-wrapper-end">
      ✅
      <p
        class="description-text"
        appTranslationModal
        [translate]="'PRODUKTAUSWAHL.GESCHAEFTSKONTO_BESCHREIBUNG_VORTEIL2'"
      ></p>
    </div>
    <div class="line-wrapper-end">
      ✅
      <p
        class="description-text"
        appTranslationModal
        [translate]="'PRODUKTAUSWAHL.GESCHAEFTSKONTO_BESCHREIBUNG_VORTEIL3'"
      ></p>
    </div>
    <br /><br />
    <p
      class="description-text"
      appTranslationModal
      [translate]="'PRODUKTAUSWAHL.GESCHAEFTSKONTO_BESCHREIBUNG_AUFWUNSCH'"
    ></p>

    <p
      class="description-text"
      appTranslationModal
      [translate]="'PRODUKTAUSWAHL.GESCHAEFTSKONTO_BESCHREIBUNG_WUNSCH1'"
    ></p>

    <p
      class="description-text"
      appTranslationModal
      [translate]="'PRODUKTAUSWAHL.GESCHAEFTSKONTO_BESCHREIBUNG_WUNSCH2'"
    ></p>

    <p
      class="description-text"
      appTranslationModal
      [translate]="'PRODUKTAUSWAHL.GESCHAEFTSKONTO_BESCHREIBUNG_WUNSCH3'"
    ></p>
    <br />
    <p
      class="description-text"
      appTranslationModal
      [translate]="'PRODUKTAUSWAHL.GESCHAEFTSKONTO_BESCHREIBUNG_PREIS'"
    ></p>
    <br />
    <div style="display: flex; flex-wrap: wrap; align-items: center; gap: 0.3rem; max-width: 100%">
      <p
        class="description-text"
        appTranslationModal
        [translate]="'PRODUKTAUSWAHL.GESCHAEFTSKONTO_BESCHREIBUNG_EURO0'"
        style="display: inline; margin: 0"
      ></p>
      <p class="description-text" style="margin: 0; display: inline">{{ berufsGruppe?.['kontoführungsgebühr'] }}*</p>
      <p
        class="description-text"
        appTranslationModal
        [translate]="'PRODUKTAUSWAHL.GESCHAEFTSKONTO_BESCHREIBUNG_EURO'"
        style="display: inline; margin: 0"
      ></p>

      <p
        class="description-text"
        appTranslationModal
        [translate]="'PRODUKTAUSWAHL.GESCHAEFTSKONTO_BESCHREIBUNG_FLAT'"
        style="display: inline; margin: 0"
      ></p>

      <span
        class="description-text"
        appTranslationModal
        [translate]="'PRODUKTAUSWAHL.GESCHAEFTSKONTO_BESCHREIBUNG_FLAT_HINWEIS'"
        style="color: gray; font-size: smaller"
      ></span>
    </div>
  </div>
  <hr />

  <p class="header-info-text" appTranslationModal [translate]="'PRODUKTAUSWAHL.ONLINE_BANKING'"></p>
  <!-- ONLINE_BANKING -->
  <div class="p-3">
    <p
      class="description-text"
      appTranslationModal
      [translate]="'PRODUKTAUSWAHL.ONLINE_BANKING_BESCHREIBUNG_VORTEILE'"
    ></p>
    <br />
    <div class="line-wrapper-end">
      ✅
      <p
        class="description-text"
        appTranslationModal
        [translate]="'PRODUKTAUSWAHL.ONLINE_BANKING_BESCHREIBUNG_VORTEIL1'"
      ></p>
    </div>
    <div class="line-wrapper-end">
      ✅
      <p
        class="description-text"
        appTranslationModal
        [translate]="'PRODUKTAUSWAHL.ONLINE_BANKING_BESCHREIBUNG_VORTEIL2'"
      ></p>
    </div>
    <div class="line-wrapper-end">
      ✅
      <p
        class="description-text"
        appTranslationModal
        [translate]="'PRODUKTAUSWAHL.ONLINE_BANKING_BESCHREIBUNG_VORTEIL3'"
      ></p>
    </div>
  </div>
  <hr />

  <p class="header-info-text" appTranslationModal [translate]="'PRODUKTAUSWAHL.POSTFACH'"></p>
  <!-- POSTFACH_BESCHREIBUNG -->
  <div class="p-3">
    <p class="description-text" appTranslationModal [translate]="'PRODUKTAUSWAHL.POSTFACH_BESCHREIBUNG_VORTEILE'"></p>
    <br />
    <div class="line-wrapper-end">
      ✅
      <p class="description-text" appTranslationModal [translate]="'PRODUKTAUSWAHL.POSTFACH_BESCHREIBUNG_VORTEIL1'"></p>
    </div>

    <div class="line-wrapper-end">
      ✅
      <p class="description-text" appTranslationModal [translate]="'PRODUKTAUSWAHL.POSTFACH_BESCHREIBUNG_VORTEIL2'"></p>
    </div>

    <div class="line-wrapper-end">
      ✅
      <p class="description-text" appTranslationModal [translate]="'PRODUKTAUSWAHL.POSTFACH_BESCHREIBUNG_VORTEIL3'"></p>
    </div>

    <div class="line-wrapper-end">
      ✅
      <p class="description-text" appTranslationModal [translate]="'PRODUKTAUSWAHL.POSTFACH_BESCHREIBUNG_VORTEIL4'"></p>
    </div>

    <div class="line-wrapper-end">
      ✅
      <p class="description-text" appTranslationModal [translate]="'PRODUKTAUSWAHL.POSTFACH_BESCHREIBUNG_VORTEIL5'"></p>
    </div>
    <div class="line-wrapper-end">
      ✅
      <p class="description-text" appTranslationModal [translate]="'PRODUKTAUSWAHL.POSTFACH_BESCHREIBUNG_VORTEIL6'"></p>
    </div>
  </div>
  <hr />

  <p class="header-info-text" appTranslationModal [translate]="'PRODUKTAUSWAHL.GIROCARD'"></p>
  <!-- GIROCARD_BESCHREIBUNG -->
  <div class="p-3">
    <p class="description-text" appTranslationModal [translate]="'PRODUKTAUSWAHL.GIROCARD_BESCHREIBUNG_VORTEILE'"></p>
    <br />
    <div class="line-wrapper-end">
      ✅
      <p class="description-text" appTranslationModal [translate]="'PRODUKTAUSWAHL.GIROCARD_BESCHREIBUNG_VORTEIL1'"></p>
    </div>
    <div class="line-wrapper-end">
      ✅
      <p class="description-text" appTranslationModal [translate]="'PRODUKTAUSWAHL.GIROCARD_BESCHREIBUNG_VORTEIL2'"></p>
    </div>

    <div class="line-wrapper-end">
      ✅
      <p class="description-text" appTranslationModal [translate]="'PRODUKTAUSWAHL.GIROCARD_BESCHREIBUNG_VORTEIL3'"></p>
    </div>

    <div class="line-wrapper-end">
      ✅
      <p class="description-text" appTranslationModal [translate]="'PRODUKTAUSWAHL.GIROCARD_BESCHREIBUNG_VORTEIL4'"></p>
    </div>

    <div class="line-wrapper-end">
      ✅
      <p class="description-text" appTranslationModal [translate]="'PRODUKTAUSWAHL.GIROCARD_BESCHREIBUNG_VORTEIL5'"></p>
    </div>
    <br /><br />
    <p class="description-text" appTranslationModal [translate]="'PRODUKTAUSWAHL.GIROCARD_BESCHREIBUNG_PREIS'"></p>
    <br />
    <p class="description-text" appTranslationModal [translate]="'PRODUKTAUSWAHL.GIROCARD_BESCHREIBUNG_EURO'"></p>
  </div>
</ng-template>

<ng-template #kontokorrentkreditInfo>
  <div class="p-3">
    <p
      class="description-text"
      appTranslationModal
      [translate]="'PRODUKTAUSWAHL.KONTOKORRENTKREDIT_BESCHREIBUNG_VORTEILE'"
    ></p>

    <ng-container *ngFor="let vorteil of [1, 2, 3, 4]">
      <div class="line-wrapper-end">
        ✅
        <p
          class="description-text"
          appTranslationModal
          [translate]="'PRODUKTAUSWAHL.KONTOKORRENTKREDIT_BESCHREIBUNG_VORTEIL' + vorteil"
        ></p>
      </div>
    </ng-container>

    <app-separator></app-separator>

    <p
      class="description-text"
      appTranslationModal
      [translate]="'PRODUKTAUSWAHL.KONTOKORRENTKREDIT_BESCHREIBUNG_KONDITIONEN'"
    ></p>
    <br />

    <p
      class="description-text"
      style="display: inline-block"
      appTranslationModal
      [translate]="'PRODUKTAUSWAHL.KONTOKORRENTKREDIT_BESCHREIBUNG_KONDITION1'"
    ></p>
    <p class="description-text" style="margin-left: 0.3rem; display: inline-block">
      {{ berufsGruppe?.kontokorrentkreditZinssatz }}
    </p>
    <p
      class="description-text"
      style="margin-left: 0.4rem; display: inline-block"
      appTranslationModal
      [translate]="'PRODUKTAUSWAHL.KONTOKORRENTKREDIT_BESCHREIBUNG_KONDITION2'"
    ></p>
  </div>
</ng-template>

<ng-template #kreditKarte>
  <div class="p-3">
    <p
      class="description-text"
      appTranslationModal
      [translate]="'PRODUKTAUSWAHL.KREDITKARTE_BESCHREIBUNG_VORTEILE'"
    ></p>

    <ng-container *ngFor="let vorteil of [1, 2, 3, 4]">
      <div class="line-wrapper-end">
        ✅
        <p
          class="description-text"
          appTranslationModal
          [translate]="'PRODUKTAUSWAHL.KREDITKARTE_BESCHREIBUNG_VORTEIL' + vorteil"
        ></p>
      </div>
    </ng-container>
    <app-separator></app-separator>

    <div style="display: flex">
      <p class="description-text" appTranslationModal [translate]="'PRODUKTAUSWAHL.KREDITKARTE_BESCHREIBUNG_PREIS'"></p>
      <p class="description-text" style="margin-left: 0.3rem; display: inline-block">
        {{ berufsGruppe?.kreditkarte }}
      </p>
    </div>
  </div>
</ng-template>

<ng-template #kontoWechselService>
  <div class="p-3">
    <p
      class="description-text"
      appTranslationModal
      [translate]="'PRODUKTAUSWAHL.KONTOWECHSELSERVICE_BESCHREIBUNG_VORTEILE'"
    ></p>

    <ng-container *ngFor="let vorteil of [1, 2, 3]">
      <div class="line-wrapper-end">
        ✅
        <p
          class="description-text"
          appTranslationModal
          [translate]="'PRODUKTAUSWAHL.KONTOWECHSELSERVICE_BESCHREIBUNG_VORTEIL' + vorteil"
        ></p>
      </div>
    </ng-container>
    <app-separator></app-separator>
    <p
      class="description-text"
      appTranslationModal
      [translate]="'PRODUKTAUSWAHL.KONTOWECHSELSERVICE_BESCHREIBUNG_PREIS'"
    ></p>
    <br />
    <p
      class="description-text"
      appTranslationModal
      [translate]="'PRODUKTAUSWAHL.KONTOWECHSELSERVICE_BESCHREIBUNG_EURO'"
    ></p>
  </div>
</ng-template>

<ng-template #tagesgeldkonto>
  <div class="p-3">
    <p
      class="description-text"
      appTranslationModal
      [translate]="'PRODUKTAUSWAHL.TAGESGELDKONTO_BESCHREIBUNG_VORTEILE'"
    ></p>

    <ng-container *ngFor="let vorteil of [1, 2, 3]">
      <div class="line-wrapper-end">
        ✅
        <p
          class="description-text"
          appTranslationModal
          [translate]="'PRODUKTAUSWAHL.TAGESGELDKONTO_BANKING_BESCHREIBUNG_VORTEIL' + vorteil"
        ></p>
      </div>
    </ng-container>

    <app-separator></app-separator>

    <p
      class="description-text"
      appTranslationModal
      [translate]="'PRODUKTAUSWAHL.TAGESGELDKONTO_BESCHREIBUNG_PREISE'"
    ></p>
    <br />
    <p
      class="description-text"
      style="display: inline-block"
      appTranslationModal
      [translate]="'PRODUKTAUSWAHL.TAGESGELDKONTO_BESCHREIBUNG_PREISE1'"
    ></p>
    <p class="description-text" style="margin-left: 0.3rem; display: inline-block">
      {{ berufsGruppe?.guthabenVerzinsung }} p.a.
    </p>
  </div>
</ng-template>

<div class="bank-wahl">
  <app-stepper style="padding: 0.5rem"></app-stepper>

  <h2 class="title">Ihre persönliche Produktauswahl</h2>
  <div style="position: relative">
    <app-geschaeftskonto
      [infoText]="geschaeftskonto"
      [kontofuehrungsgebuehr]="berufsGruppe?.['kontoführungsgebühr']"
    ></app-geschaeftskonto>

    <div class="zubuchbar-container tagesgeldkonto-card" *ngIf="enableTagesgeldkonto && berufsGruppe?.tagesgeldkonto">
      <app-zubuchbar-card
        title="Tagesgeldkonto"
        subtitle="{{ berufsGruppe?.guthabenVerzinsung }}  p.a."
        [checkboxFormControl]="answerFormGroup.controls['tagesgeldkonto']"
        text="Guthabenverzinsung"
        [checkboxDisabled]="true"
        [infoText]="tagesgeldkonto"
      ></app-zubuchbar-card>
    </div>
  </div>
  <app-separator></app-separator>

  <div class="zubuchbar-container">
    <h2 class="title">Zubuchbare Optionen</h2>

    <div class="cards">
      <app-zubuchbar-card
        title="Kontokorrentkredit"
        subtitle="{{ berufsGruppe?.kontokorrentkreditZinssatz }}"
        text="Sollzins p.a."
        [checkboxFormControl]="answerFormGroup.controls['kontokorrentkredit']"
        [infoText]="kontokorrentkreditInfo"
        [showAdditionalCheckbox]="berufsGruppe?.abtretung === true"
        [disabled]="answerFormGroup.controls['kontokorrentkredit'].value === false"
      ></app-zubuchbar-card>
      <app-zubuchbar-card
        title="Kreditkarte"
        subtitle="{{ berufsGruppe?.kreditkarte }}"
        text="pro Vertragsjahr"
        [checkboxFormControl]="answerFormGroup.controls['kreditKarte']"
        [infoText]="kreditKarte"
      ></app-zubuchbar-card>
      <app-zubuchbar-card
        title="Kontowechselservice"
        subtitle="kostenlos"
        [checkboxFormControl]="answerFormGroup.controls['kontoWechselService']"
        [infoText]="kontoWechselService"
      ></app-zubuchbar-card>
    </div>
  </div>

  <app-separator></app-separator>
  <app-button
    (click)="btnService.handleSaveAndDisableButton()"
    color="primary"
    [disabled]="btnService.isButtonDisabled() || btnService.isSaveAndContinueDisabled"
  >
    <span class="button-size-big" appTranslationModal [translate]="questionService.getNextButtonTitle()"></span>
  </app-button>
  <app-separator></app-separator>
</div>
