<div class="modal-overlay" *ngIf="isVisible">
  <div class="modal-content">
    <ng-content></ng-content>
    <app-separator></app-separator>
    <div class="modal-buttons">
      <app-button (click)="closeModal()" *ngIf="showCloseButton">{{ closeButtonText }}</app-button>
      <app-button (click)="acceptModal()" *ngIf="showAcceptButton">{{ acceptButtonText }}</app-button>
    </div>
  </div>
</div>
