<mat-icon class="clickable help-icon" (click)="openDialog()">help_outline</mat-icon>

<ng-template #infoContent>
  <div class="modal">
    <article class="modal-container">
      <section class="modal-container-body rtf">
        <div class="info-container">
          <ng-content></ng-content>
        </div>
      </section>
      <app-button class="app-btn" (click)="closeModal()">Okay</app-button>
    </article>
  </div>
</ng-template>
