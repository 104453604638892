import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
    selector: 'app-inputtext',
    imports: [CommonModule, ReactiveFormsModule, TranslateModule],
    templateUrl: './inputtext.component.html',
    styleUrl: './inputtext.component.scss'
})
export class InputtextComponent implements OnInit {
  @Input() label!: string;
  @Input() placeholder: string = '';
  @Input() control: any;
  @Input() id!: string;
  @Input() readonly: boolean = false;
  @Input() value: any;

  ngOnInit(): void {
    if (this.value !== undefined) {
      this.value = this.value; //TODO
    }
    if (this.control) return;
    this.control = new FormControl();
  }
}
