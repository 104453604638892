<div class="dialogBox">
  <h3 appTranslationModal [translate]="headerText"></h3>
  <p class="subTextStyle" appTranslationModal [translate]="subText"></p>
  <div style="display: flex; justify-content: space-evenly" class="mt-4">
    <app-button color="accent" [mat-dialog-close]="false" data-cy="app-default-dialog-box-false">
      {{ decline }}
    </app-button>
    <app-button color="accent" [mat-dialog-close]="true" data-cy="app-default-dialog-box-true">
      {{ confirm }}
    </app-button>
  </div>
</div>
