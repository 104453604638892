<div class="same-line">
  <label class="material-checkbox">
    <input
      type="checkbox"
      [(ngModel)]="checkBoxControl!.value"
      (click)="triggervalidation($event)"
      [disabled]="isDisabled"
    />
    <span class="checkmark"></span>
  </label>
  <ng-content></ng-content>
</div>
