<div class="form__group field full-width">
  <input
    type="text"
    class="form__field"
    [placeholder]="placeholder"
    [formControl]="control"
    [value]="value"
    [readonly]="readonly"
    id="{{ id }}"
    [ngClass]="{ valid: control?.valid }"
  />
  <label [for]="id" class="form__label"> {{ (label | translate) ?? label }} {{ control?.required ? '*' : '' }} </label>
  @if (control?.valid && control.value) {
    <span class="suffix-icon">&#x2713;</span>
  }
</div>
