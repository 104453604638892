import { inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { DefaultDialogBoxComponent } from '../component/dialog-boxes/default-dialog-box/default-dialog-box.component';
import { PepDialogBoxComponent } from '../component/dialog-boxes/pep-dialog-box/pep-dialog-box.component';
import { Status, User } from '../models/user';
import { QuestionTemplate } from '../pages/questionnaire/questions/question-template';
import { AdminService } from './admin.service';
import { Agree21Service } from './agree21.service';
import { AuthService } from './auth.service';
import { DataService } from './data.service';
import { TokenService } from './jwttoken.service';
import { QuestionService } from './question.service';

@Injectable({
  providedIn: 'root',
})
export class ButtonService {
  private currentQuestionIndex: number;
  isSaveAndContinueDisabled: boolean;
  answerFormGroup: FormGroup;
  user: User;
  berufsGruppe: any;
  isLoading: boolean;
  currentPath: string;
  router = inject(Router);
  route = inject(ActivatedRoute);

  constructor(
    public translate: TranslateService,
    public dialog: MatDialog,
    private questionService: QuestionService,
    private auth: AuthService,
    private adminService: AdminService,
    private dataService: DataService,
    private agree21Service: Agree21Service,
    public tokenservice: TokenService
  ) {
    this.currentPath = this.getCurrentPath();
    this.currentQuestionIndex = this.questionService.getQuestionIndexByRoute(this.currentPath);
    const questionObj = this.questionService.getQuestionObjByRoute(this.currentPath);
    if (questionObj?.component) {
      console.log('AM HEREEEEEE');
      this.answerFormGroup = questionObj.component.answerFormGroup;
    }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.isSaveAndContinueDisabled = false; // Reset button state on navigation
      }
    });
  }

  nextStep() {
    const component = this.questionService.getComponent(this.currentQuestionIndex);
    const nextRoute = this.questionService.getRouteName(this.currentQuestionIndex + 1);
    // not allow spring questions on double click
    if (nextRoute && component && (!component.answerFormGroup || component.answerFormGroup.valid)) {
      console.log(component);
      this.router.navigate([nextRoute], { relativeTo: this.route });
      //   await this._init(nextRoute);
    }
  }

  getCurrentPath() {
    return window.location.href.replace('://', '').split('/')[1];
  }

  getNextButtonTitle() {
    const currentPath = this.getCurrentPath();
    const currentQuestionIndex = this.questionService.getQuestionIndexByRoute(currentPath);
    return this.questionService.questionList[currentQuestionIndex].nextButtonTitle;
  }

  handleSaveAndDisableButton() {
    this.isSaveAndContinueDisabled = true;
    this.saveAndContinue();
  }

  isButtonDisabled(): boolean {
    const currentPath = this.getCurrentPath();
    const questionObj = this.questionService.getQuestionObjByRoute(currentPath);
    const currentQuestionIndex = this.questionService.getQuestionIndexByRoute(currentPath);
    if (questionObj?.component) {
      this.answerFormGroup = questionObj.component.answerFormGroup;
    }
    const component = this.questionService.getComponent(currentQuestionIndex);

    if (component && component.questionName === 'bank-wahl') {
      const kontokorrentkredit = this.answerFormGroup.controls?.['kontokorrentkredit'].value;
      if (this.berufsGruppe?.abtretung === true) {
        const abtretungsvertrag = this.answerFormGroup.controls?.['abtretungsvertrag'].value;

        // Disable the button if kontokorrentkredit is true and abtretungsvertrag is not true
        return kontokorrentkredit && !abtretungsvertrag;
      }

      // If the language is not 'in Produktmatrix', don't apply the abtretungsvertrag check
      return false;
    }

    // For other questions, the button is disabled based on the form's validity
    return this.answerFormGroup && !this.answerFormGroup.valid;
  }

  async saveAndContinue() {
    const currentPath = this.getCurrentPath();
    const currentQuestionIndex = this.questionService.getQuestionIndexByRoute(currentPath);
    const component = this.questionService.getComponent(currentQuestionIndex);
    if (component && component.questionName === 'bank-wahl') {
      const { kreditKarte, kontokorrentkredit, kontoWechselService } = this.answerFormGroup.controls;

      const kreditKarteValue = kreditKarte?.value;
      const kontokorrentkreditValue = kontokorrentkredit?.value;
      const kontoWechselServiceValue = kontoWechselService?.value;
      if (!kreditKarteValue || !kontokorrentkreditValue || !kontoWechselServiceValue) {
        await this.showConfirmationDialog(
          'PRODUKTAUSWAHL.BESTÄTIGUNG_PRODUKTAUSWAHL_HEADER',
          'PRODUKTAUSWAHL.BESTÄTIGUNG_PRODUKTAUSWAHL_TEXT',
          () => this.saveAnswer(component)
        );
      } else {
        // In all other cases, just save the answer without showing the confirmation dialog
        this.saveAnswer(component);
      }
      this.adminService.updateSubStatus(this.auth.user._id, 'inProcess').subscribe(() => {});
    } else if (component && component.questionName === 'blanko-dokumente') {
      const offentlichesAmt = this.answerFormGroup.controls?.['offentlichesAmt'].value;

      if (offentlichesAmt === 'true') {
        await this.showPepDialog(() => {
          this.isLoading = true;
          this.adminService.sendPepEmail(this.auth.user._id).subscribe(async () => {
            this.adminService.updateSubStatus(this.auth.user._id, 'pep').subscribe(() => {
              this.auth.changeStatus(this.auth.user._id, Status.inactive).subscribe(() => {
                this.isLoading = false;
                window.location.href = environment.oneDashboardURL;
              });
            });
          });
        });
      } else {
        this.saveAnswer(component);
      }
    } else if (component && component.questionName === 'einsicht') {
      if (!this.auth.user?.giroCardOrdered) {
        this.auth.refreshUser().subscribe(async (user) => {
          const index = this.auth.findFlaggedNaturalPerson(this.auth.user.natPersonen);
          const Personennummer = user.isJurPerson
            ? user.natPersonen[index].BBKundennummer
            : this.agree21Service?.PERSONEN_NUMMER || user.kundenNummer;

          await this.processGirocard(this.auth.user.isJurPerson, Personennummer);
        });
      } else {
        console.log('schon gemacht - postGirocardOnlineBanking');
      }
      this.saveAnswer(component);
      this.dataService.archiveAlldocuments().subscribe(() => {});
      this.auth.changeStatus(this.auth.user._id, Status.finish).subscribe(() => {});

      if (this.auth.user.questions[0].answer.kontoWechselService === true) {
        this.adminService.updateSubStatus(this.auth.user._id, 'kws').subscribe(() => {});
      }
    } else if (component) {
      this.saveAnswer(component);
    }
  }

  async processGirocard(isJurPerson: boolean, Personennummer: number) {
    const action = isJurPerson ? 'JurPerson' : 'NatPerson';

    this.agree21Service
      .postGirocardOnlineBanking(Personennummer, this.agree21Service.IBAN, this.agree21Service.IBAN)
      .subscribe((result) => {
        if (!result) {
          throw new Error(`Failed - girocardOnlineBankingFor${action}`);
        }
      });
  }

  async showConfirmationDialog(headerText, subText, callback) {
    const answer = await this.dialog
      .open(DefaultDialogBoxComponent, {
        data: {
          headerText,
          subText,
        },
      })
      .afterClosed()
      .toPromise();

    if (answer === true) {
      callback();
    } else {
      this.isSaveAndContinueDisabled = false;
    }
  }

  async showPepDialog(callback) {
    const answer = await this.dialog.open(PepDialogBoxComponent).afterClosed().toPromise();

    if (answer === true) {
      callback();
    }
  }

  async saveAnswer(component: QuestionTemplate) {
    if (component && component.answerFormGroup && component.answerFormGroup.valid)
      component.saveAnswer().subscribe(
        async () => {
          await this.questionService.nextStep();
        },
        () => {
          setTimeout(() => window.location.reload(), 1000);
        }
      );
    else {
      await this.questionService.nextStep();
    }
  }
}
