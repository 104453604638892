<div class="card-container box-shadow">
  <!-- Info Text -->
  <div class="info-text">
    <app-info-card>
      <ng-container *ngIf="infoText && infoText; instanceof: TemplateRef; else: stringContent">
        <ng-container *ngTemplateOutlet="infoText"></ng-container>
      </ng-container>
      <ng-template #stringContent>
        <p [innerHTML]="infoText"></p>
      </ng-template>
    </app-info-card>
  </div>

  <div style="display: flex">
    <!-- Main Checkbox -->
    <app-checkbox [checkBoxControl]="checkboxFormControl" [isDisabled]="checkboxDisabled"></app-checkbox>
  </div>

  <p class="title">{{ title }}</p>
  <p class="subtitle">{{ subtitle }}</p>
  <p class="text">{{ text }}</p>

  <!-- Additional Checkbox -->
  <div *ngIf="showAdditionalCheckbox">
    <app-checkbox [checkBoxControl]="checkboxFormControl" [isDisabled]="true">
      <p
        [ngClass]="{ 'disabled-text': disabled }"
        class="title"
        appTranslationModal
        [translate]="'PRODUKTAUSWAHL.ABTRETUNGSVERTRAG'"
      ></p>
    </app-checkbox>
  </div>
</div>
